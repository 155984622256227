body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.graphiql-container{
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.doc-explorer-contents{
  overflow-y: auto !important;
}
.doc-explorer-title{
  font-size: var(--font-size-h2);
    font-weight: var(--font-weight-medium);
    padding: 0 14px;;
}
.docExplorerHide{
  display: none;
}
.docExplorerWrap {
  background-color: hsla(var(--color-neutral),var(--alpha-background-light));
  border-radius: calc(var(--border-radius-12) + var(--px-8));
  display: flex;
  flex-direction: column;
  margin-left: 0;
  min-width: 0;
  width: 100%!important;
  overflow-x: hidden;
}

.docExplorerWrap {
  height: unset !important;
  min-width: unset !important;
  width: unset !important;
}

.doc-explorer-title {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-medium);
}

.doc-explorer-rhs {
  display: none;
}

.graphiql-explorer-root {
  font-family: var(--font-family-mono) !important;
  font-size: var(--font-size-body) !important;
  padding: 0 !important;
}

.graphiql-explorer-root > div:first-child {
  padding-left: var(--px-8);
}

.graphiql-explorer-root input {
  background: hsl(var(--color-base));
}

.graphiql-explorer-root select {
  background-color: hsl(var(--color-base));
  border: 1px solid hsla(var(--color-neutral), var(--alpha-secondary));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-neutral));
  margin: 0 var(--px-4);
  padding: var(--px-4) var(--px-6);
}