.graphiql-container {
  height: 100vh;
  width: 100vw;
}
.server-info{
  position: fixed;
    right: 1.8rem;
    top: 3.4rem;
    font-size: 12px;
    border-radius: 3px;
    /* border: 1px solid rgba(183,194,215,.76); */
    padding: 2px 5px;
    color: rgb(255, 87, 148);
}